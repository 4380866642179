import React from 'react';
import logo from './logo.svg';
import './App.css';
import DosPlayer from "./DosPlayer";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App" style={{ width: "100vmin", height: "100vmin" }}>
          <DosPlayer bundleUrl="jsdos-bundle.jsdos"/>
        </div>
      </header>
    </div>
  );
}

export default App;
